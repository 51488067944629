import { getServiceWorkerRegistration } from './service-worker-registration';
if ('Notification' in window && 'PushManager' in window) {
  document.documentElement.classList.add(
    'notifications-supported',
    `notifications-${Notification.permission || 'default'}`
  );
}
if ('Notification' in window && Notification.permission === 'granted') {
  let pushSubscriptions: string[] = [];
  try {
    pushSubscriptions = JSON.parse(
      (document.querySelector('[data-push-subscriptions]') as HTMLScriptElement)
        ?.dataset.pushSubscriptions || '[]'
    );
  } catch (e) {}
  getServiceWorkerRegistration()
    .then((registration) => {
      registration?.pushManager.getSubscription().then((subscription) => {
        if (!pushSubscriptions.includes(subscription?.endpoint || '')) {
          document.documentElement.classList.remove('notifications-granted');
        }
      });
    })
    .catch(console.error);
}
